import { render, staticRenderFns } from "./NewMedia.vue?vue&type=template&id=0ffb91e4&scoped=true"
import script from "./NewMedia.vue?vue&type=script&lang=js"
export * from "./NewMedia.vue?vue&type=script&lang=js"
import style0 from "./NewMedia.vue?vue&type=style&index=0&id=0ffb91e4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ffb91e4",
  null
  
)

export default component.exports